import { useCallback, useEffect, useRef, useState } from 'react';

import * as Sentry from '@sentry/react';

import { getLanguageCode } from 'js/lib/language';
import thirdParty from 'js/lib/thirdParty';

export const SCRIPT_URL = 'https://coursera-api.arkoselabs.com/v2/98EFB87D-3554-499A-B9C9-4285EDF0DF57/api.js'; // Exported for use in unit test
const STATUS_URL = 'https://status.arkoselabs.com/api/v2/status.json';
const MAXIMUM_RETRIES = 3;
const MAXIMUM_TIMEOUT_DURATION = 7500;

export const USER_CLOSED_CHALLENGE = 'Challenge closed by user';

const makeErrorMessage = (error: string) => `arkose${error}Error`;

// Error List
const MAX_ENFORCEMENT_RETRIES_ERROR = makeErrorMessage('MaxEnforcementRetries');
const GENERIC_CLIENT_SIDE_ERROR = makeErrorMessage('GenericClientSide');
const SCRIPT_LOAD_ERROR = makeErrorMessage('ScriptLoad');
export const ENFORCEMENT_FAILED_ERROR = makeErrorMessage('EnforcementFailed'); // Exported for use in unit test
const SCRIPT_CONFIGURATION_ERROR = makeErrorMessage('ScriptConfiguration');

// TODO: Consolidate this with the error from above
export const ARKOSE_CLIENT_SIDE_ERROR = makeErrorMessage('GenericClientSide');

type Props = {
  publicKey?: string;
  shouldSkipHook?: boolean;
  onHideCallback?: () => void;
};

type Enforcement = {
  setConfig: (config: {
    language: string;
    onReady: () => void;
    onHide: () => void;
    onCompleted: (response: { token: string }) => void;
    onError: (error?: { error: string }) => void;
  }) => void;
  run: () => void;
  reset: () => void;
};

declare global {
  interface Window {
    setupEnforcement?: (enforcement: Enforcement) => void;
  }
}

export type ArkoseBotManager = {
  reset: () => void | undefined;
  getToken: () => Promise<string>;
};

function getLanguage() {
  const code = getLanguageCode();
  return code === 'zh-mo' ? 'zh' : code;
}

/**
 * Custom React hook that manages the integration with Arkose Labs for bot detection.
 * This hook handles loading the Arkose Labs API script, setting up enforcement,
 * retry logic, timeout management, and API health checking.
 *
 * @param {string} publicKey - The Arkose Labs public key required for API integration.
 *                             Different flows may use different public keys.
 * @param {boolean} [shouldSkipHook=false] - The boolean to control whether the hook's logic should be executed.
 * @param {function} onHideCallback Callback invoked when the challenge is closed via the ESC key, the X close button, or after the onCompleted callback.
 *
 * @returns {object} - Returns an object with the following methods:
 *   - `reset`: A method to reset the Arkose Labs enforcement if necessary.
 *   - `getToken`: A method to initiate the enforcement process and return a token when no challenge is needed OR the challenge is completed.
 */
const useArkoseBotManager = ({ shouldSkipHook = false, onHideCallback }: Props): ArkoseBotManager => {
  const enforcementRef = useRef<Enforcement | null>(null);
  const tokenPromiseRef = useRef<Promise<string>>(new Promise(() => {}));
  const tokenResolverRef = useRef<(token: string) => void>(() => {});
  const tokenRejecterRef = useRef<(error: Error | string) => void>(() => {});
  const timeoutIdRef = useRef<ReturnType<typeof setTimeout>>();
  const onReadyRetryCountRef = useRef(0);

  const [enforcementRetryCount, setEnforcementRetryCount] = useState(0);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  // Helper function to create a new token promise
  const createNewTokenPromise = () => {
    tokenPromiseRef.current = new Promise<string>((resolve, reject) => {
      tokenResolverRef.current = resolve;
      tokenRejecterRef.current = reject;
    });
  };

  const fetchArkoseAPIHealthStatus = async () => {
    const response = await fetch(STATUS_URL);
    return response.json();
  };

  const handleFailure = useCallback((error: string, extraDetails: string) => {
    // We currently move the hook load from sign up modal load to logged out page load, so we need to
    // sample the error logs manually to 10% to make sure we don't hit the Sentry budget
    if (Math.random() < 0.1) {
      Sentry.captureException(`useArkoseBotManager ${error}`, { extra: { extraDetails } });
    }
    tokenRejecterRef.current(new Error(error));
  }, []);

  const onCompleted = useCallback((token) => {
    tokenResolverRef.current(token);
  }, []);

  const onHide = useCallback(() => {
    if (onHideCallback) onHideCallback();
  }, [onHideCallback]);

  const onReady = () => {
    clearTimeout(timeoutIdRef.current);
  };

  const onError = useCallback(
    async (error?: string) => {
      let isHealthyStatus = false;

      try {
        const { status } = await fetchArkoseAPIHealthStatus();
        isHealthyStatus = status?.indicator === 'none';

        if (!isHealthyStatus) {
          Sentry.captureException(`useArkoseBotManager Unhealthy Status`, { extra: status?.indicator });
        }
      } catch (healthStatusError) {
        Sentry.captureException(`useArkoseBotManager Health Status Check Failed`, { extra: healthStatusError });
      }

      if (isHealthyStatus && enforcementRetryCount < MAXIMUM_RETRIES) {
        enforcementRef.current?.reset();
        setEnforcementRetryCount((prev) => prev + 1);
        // To ensure the enforcement has been successfully reset, we need to set a timeout here
        setTimeout(() => {
          enforcementRef.current?.run();
        }, 500);
        return;
      }

      if (error && enforcementRetryCount === MAXIMUM_RETRIES) {
        handleFailure(MAX_ENFORCEMENT_RETRIES_ERROR, error);
        return;
      }
      if (error) {
        handleFailure(GENERIC_CLIENT_SIDE_ERROR, error);
      }
    },
    [enforcementRetryCount, handleFailure]
  );

  const handleScriptSuccess = () => {
    setIsScriptLoaded(true);
  };

  const loadScript = useCallback(async () => {
    thirdParty.removeScript(SCRIPT_URL);

    try {
      await thirdParty.loadScript({
        url: SCRIPT_URL,
        attributes: {
          defer: true,
          'data-callback': 'setupEnforcement',
        },
        retries: MAXIMUM_RETRIES,
        timeout: MAXIMUM_TIMEOUT_DURATION,
      });

      handleScriptSuccess();
    } catch (error) {
      handleFailure(SCRIPT_LOAD_ERROR, error);
    }
  }, [handleFailure]);

  // Function to setup enforcement after the Arkose script loads
  const setupEnforcement = useCallback(
    (enforcement: Enforcement) => {
      enforcementRef.current = enforcement;
      enforcementRef.current.setConfig({
        language: getLanguage(),
        onReady: () => onReady(),
        onHide: () => onHide(),
        onCompleted: (response) => onCompleted(response.token),
        onError: (response) => onError(response?.error),
      });
    },
    [onCompleted, onError, onHide]
  );

  useEffect(() => {
    if (shouldSkipHook) return;

    // Try loading the script
    loadScript();

    // Set window setupEnforcement function
    window.setupEnforcement = setupEnforcement;

    createNewTokenPromise();
  }, [loadScript, setupEnforcement, shouldSkipHook]);

  useEffect(() => {
    if (shouldSkipHook) return () => {};

    // Timeout check for onReady callback,
    // if onReady is not triggered when timeout, try reset the enforcement object
    timeoutIdRef.current = setTimeout(() => {
      if (onReadyRetryCountRef.current > MAXIMUM_RETRIES) {
        handleFailure(
          SCRIPT_CONFIGURATION_ERROR,
          `Failed to configure the Arkose API Script (onReady is not called) after ${MAXIMUM_RETRIES} attempts`
        );
      } else if (isScriptLoaded) {
        onReadyRetryCountRef.current += 1;
        enforcementRef.current?.reset();
      }
    }, MAXIMUM_TIMEOUT_DURATION);

    return () => {
      clearTimeout(timeoutIdRef.current);
    };
  }, [isScriptLoaded, handleFailure, shouldSkipHook]);

  // Separate effect for unmount cleanup
  useEffect(() => {
    return () => {
      if (shouldSkipHook) return;

      if (window.setupEnforcement) {
        delete window.setupEnforcement;
      }
      thirdParty.removeScript(SCRIPT_URL);
    };
  }, [shouldSkipHook]);

  if (shouldSkipHook) {
    return {
      reset: () => {},
      getToken: () => Promise.reject(new Error('Hook skipped')),
    };
  }

  return {
    // Expose a method to reset the enforcement
    reset: () => {
      if (!enforcementRef.current) {
        Sentry.captureException('useArkoseBotManager error', {
          extra: {
            error: 'Enforcement was not configured successfully when calling reset(), something unexpected happended',
          },
        });
      } else {
        createNewTokenPromise();
        enforcementRef.current.reset();
      }
    },
    getToken: () => {
      createNewTokenPromise();
      if (!enforcementRef.current) {
        handleFailure(
          ENFORCEMENT_FAILED_ERROR,
          'Enforcement was not configured successfully when calling run(), potentially due to a failure or delay in loading the script'
        );
      } else {
        enforcementRef.current.run();
      }
      // Consider implementing a timeout for the promise to prevent it from remaining pending due to potential Arkose API failures.
      return tokenPromiseRef.current;
    },
  };
};

export default useArkoseBotManager;
