/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

import { isAuthenticatedUser } from 'js/lib/user';

import { Button, Dialog, InlineNotification, PasswordField, TextField, Typography2 } from '@coursera/cds-core';

import InternationalPhoneNumberInput from 'bundles/account-profile/components/i18n-phone-number-input/InternationalPhoneNumberInput';
import type { ProfilePhone } from 'bundles/account-profile/components/types';
import {
  RECAPTCHA_ACTIONS,
  SIGNUP_REDESIGN_RECAPTCHA_ID,
  EMAIL_CONSTRAINT as isEmailValid,
  PASSWORD_CONSTRAINT as isPasswordValid,
} from 'bundles/authentication/constants';
import Separator from 'bundles/authentication/shared/Separator';
import * as actions from 'bundles/authentication/shared/actions';
import useBotManager from 'bundles/authentication/shared/hooks/useBotManager';
import { useEoiOptimizationsContext } from 'bundles/expression-of-interest/components/EoiOptimizationsContext';
import { EoiSSO } from 'bundles/expression-of-interest/components/authentication/EoiSSO';
import { useEoiEventing } from 'bundles/expression-of-interest/utils/eventingUtils';
import {
  firstNameErrorCheck,
  fullNameErrorCheck,
  isDestinationDegree,
  lastNameErrorCheck,
} from 'bundles/expression-of-interest/utils/util';
import type { Phone } from 'bundles/naptimejs/resources/__generated__/ProfilesV1';
import { customFormattedPhoneStyles } from 'bundles/university-program-qualification/components/SurveyQuestion';
import { ProductTypes } from 'bundles/university-program-qualification/utils/constants';
import Instrumentation from 'bundles/userModal/lib/instrumentation';

import _t from 'i18n!nls/expression-of-interest';

type Props = {
  handleFirstNameChange: (firstName: string) => void;
  handleLastNameChange: (lastName: string) => void;
  handleEmailChange: (email: string) => void;
  handlePhoneChange: (phone: ProfilePhone) => void;
  firstName: string;
  lastName: string;
  learnerEmail: string;
  phone: Phone;
  handleSubmit: () => Promise<void | {}>;
  navigateLogin: () => void;
  navigateSelectSubject: () => void;
  navigateConfirmation: () => void;
  trackingProductId: string;
  setIsNewSignup: (userSignedUp: boolean) => void;
  shouldSetAutoFocus?: boolean;
};

const styles = {
  textField: css`
    margin-bottom: var(--cds-spacing-150);

    label {
      margin: 0;
    }
  `,

  divider: css`
    margin-bottom: var(--cds-spacing-150);
    margin-top: var(--cds-spacing-150);
  `,

  supportText: css`
    margin-top: var(--cds-spacing-150);
    margin-bottom: var(--cds-spacing-150);
  `,
  switchToLogin: css`
    display: flex;
    margin: auto;
  `,
  reCAPTCHA: css`
    display: none;
  `,
};

export const InitialSection = (props: Props): React.ReactElement => {
  const {
    handleFirstNameChange,
    handleLastNameChange,
    handleEmailChange,
    handlePhoneChange,
    firstName,
    lastName,
    learnerEmail,
    phone,
    handleSubmit,
    navigateLogin,
    navigateSelectSubject,
    navigateConfirmation,
    trackingProductId,
    setIsNewSignup,
    shouldSetAutoFocus,
  } = props;

  const { slug, productType } = useEoiOptimizationsContext();
  const isDestinationDegreeEoi = isDestinationDegree(slug);

  const isLoggedOut = !isAuthenticatedUser();

  const phoneNumberRef = useRef<HTMLInputElement>(null);
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [hasFormError, setHasFormError] = useState<boolean>(false);
  const [firstNameError, setFirstNameError] = useState<string>('');
  const [lastNameError, setLastNameError] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [hasPhoneError, setHasPhoneError] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<string>('');
  const [isRunning, setIsRunning] = useState(false);

  const {
    isRetrievingTokenRef,
    getToken: getBotManagerToken,
    reset: resetBotManager,
  } = useBotManager({
    reCAPTCHA: {
      container: SIGNUP_REDESIGN_RECAPTCHA_ID,
      options: { action: RECAPTCHA_ACTIONS.signup },
    },
  });

  const { trackSubmitEoi, trackInteractEoi } = useEoiEventing(trackingProductId);

  // Field validations
  useEffect(() => {
    const error = firstNameErrorCheck(firstName);
    if (error) {
      setFirstNameError(error);
    } else {
      setFirstNameError('');
    }
  }, [firstName]);

  useEffect(() => {
    const error = lastNameErrorCheck(lastName);
    if (error) {
      setLastNameError(error);
    } else {
      setLastNameError('');
    }
  }, [lastName]);

  useEffect(() => {
    const error = isEmailValid(learnerEmail);
    if (error) {
      setEmailError(error);
    } else {
      setEmailError('');
    }
  }, [learnerEmail]);

  useEffect(() => {
    const error = isPasswordValid(password);

    if (error) {
      setPasswordError(error);
    } else {
      setPasswordError('');
    }
  }, [password]);

  useEffect(() => {
    if (phone.phoneNumber === '') {
      setHasPhoneError(true);
    } else {
      setHasPhoneError(false);
    }
  }, [phone]);

  const navigateNext = () => {
    if (productType === ProductTypes.DEGREE_HUB) {
      navigateSelectSubject();
    } else {
      navigateConfirmation();
    }
  };
  // Create a Coursera account and submit EOI
  const handleCreateAccount = async () => {
    // Exit early if a token retrieval is in progress to prevent multiple function calls.
    if (isRetrievingTokenRef?.current) return false;

    const fullName = `${firstName?.trim()} ${lastName?.trim()}`;
    const fullNameError = fullNameErrorCheck(fullName);

    if (firstNameError || lastNameError || emailError || passwordError || hasPhoneError) {
      trackSubmitEoi('invalid_submission', 'initial_section');
      return setHasFormError(true);
    }
    if (fullNameError) {
      setSubmitError(fullNameError);
      trackSubmitEoi('invalid_submission', 'initial_section');
      return setHasFormError(true);
    }

    const name = `${firstName?.trim()} ${lastName?.trim()}`;

    const token = await getBotManagerToken();

    setIsRunning(true);

    try {
      // API call to create a new account
      await actions.signup({ email: learnerEmail, name, password, token });
      trackInteractEoi('signup', 'initial_section');

      // Submit the EOI
      handleSubmit();
      navigateNext();
      setIsNewSignup(true);
      Instrumentation.register();
      return trackSubmitEoi('success', 'initial_section');
    } catch (error) {
      trackSubmitEoi('api_failure', 'initial_section');

      resetBotManager();

      if (error.responseJSON.errorCode === 'existingCourseraAccount') {
        setSubmitError(_t('This email is already associated with an account. Log in or use a different email.'));
      } else {
        setSubmitError(_t("Make sure all the information you've submitted is correct"));
      }
      setIsRunning(false);
    }
  };

  const { HeadingGroup, Content, Actions } = Dialog;

  const disclaimerText = isDestinationDegreeEoi
    ? _t(
        'By sharing my phone number, I consent to receiving calls, texts or emails from Coursera and the university about educational opportunities. '
      )
    : _t(
        'By sharing my phone number, I consent to receiving calls or texts from Coursera about educational opportunities. '
      );

  const isDegree = productType === ProductTypes.DEGREE;
  const headingText = isDegree ? _t('Learn more about this program') : _t('Learn more');

  return (
    <>
      <HeadingGroup
        supportText={_t(
          'Sign up or login to your account so we can email you information on upcoming enrollment dates, webinars, and program news.'
        )}
      >
        {headingText}
      </HeadingGroup>
      <Content data-testid="eoi-initial-section">
        {isLoggedOut && (
          <>
            <>
              <EoiSSO trackingProductId={trackingProductId} shouldSetAutoFocus={shouldSetAutoFocus} />
            </>

            <Separator text={_t('or')} />
            <Button variant="ghost" css={styles.switchToLogin} onClick={navigateLogin}>
              {_t('Log in with your Coursera account')}
            </Button>
          </>
        )}

        {submitError && (
          <InlineNotification
            title={_t('There was an error with your submission')}
            severity="error"
            aria-live="assertive"
          >
            {submitError}
          </InlineNotification>
        )}

        <p aria-live="assertive" className="sr-only">
          {hasFormError && firstNameError}
          {hasFormError && lastNameError}
          {hasFormError && emailError}
          {hasFormError && passwordError}
        </p>

        <div css={styles.reCAPTCHA} id={SIGNUP_REDESIGN_RECAPTCHA_ID} data-testid="recaptcha-signup" />

        <TextField
          label={_t('First Name')}
          fullWidth
          css={styles.textField}
          onChange={(e) => handleFirstNameChange(e.target.value)}
          validationStatus={hasFormError && !!firstNameError ? 'error' : undefined}
          validationLabel={firstNameError}
          value={firstName}
          placeholder={_t('First name')}
        />

        <TextField
          label={_t('Last Name')}
          fullWidth
          css={styles.textField}
          onChange={(e) => handleLastNameChange(e.target.value)}
          validationStatus={hasFormError && !!lastNameError ? 'error' : undefined}
          validationLabel={lastNameError}
          value={lastName}
          placeholder={_t('Last name')}
        />

        <TextField
          label={_t('Email')}
          type="email"
          fullWidth
          css={styles.textField}
          onChange={(e) => handleEmailChange(e.target.value)}
          validationStatus={hasFormError && !!emailError ? 'error' : undefined}
          validationLabel={emailError}
          value={learnerEmail}
          autoComplete="email"
          placeholder="name@email.com" // This matches the current global login form
        />

        <PasswordField
          label={_t('Password')}
          fullWidth
          onChange={(e) => setPassword(e.target.value)}
          validationStatus={hasFormError && !!passwordError ? 'error' : undefined}
          validationLabel={passwordError}
          value={password}
          supportText={_t('Between 8 and 72 characters')}
          css={styles.textField}
          placeholder={_t('Enter your password')}
        />

        <InternationalPhoneNumberInput
          profile={{ phone }}
          isOptional={false}
          isEmptyValid={false}
          customStyles={customFormattedPhoneStyles}
          submissionError={hasFormError}
          handleFormChange={handlePhoneChange}
          handleError={(err) => {
            if (err) {
              setHasFormError(true);
            }
          }}
          ref={phoneNumberRef}
        />

        <Typography2 component="p" variant="bodySecondary" color="supportText" css={styles.supportText}>
          {disclaimerText}
        </Typography2>
      </Content>

      <Actions>
        <Button onClick={handleCreateAccount} disabled={isRunning}>
          {_t('Join for free')}
        </Button>
      </Actions>
    </>
  );
};

export default InitialSection;
