import { useCallback, useRef } from 'react';

import { ARKOSE_PUBLIC_KEY_SIGN_UP, SIGNUP_REDESIGN_RECAPTCHA_ID } from 'bundles/authentication/constants';
import type { ArkoseBotManagerContext } from 'bundles/authentication/shared//types/sharedTypes';
import useArkoseBotManager from 'bundles/authentication/shared/hooks/useArkoseBotManager';
import type { ArkoseBotManager } from 'bundles/authentication/shared/hooks/useArkoseBotManager';
import { useRecaptcha } from 'bundles/authentication/shared/hooks/useRecaptcha';
import type { Options } from 'bundles/authentication/shared/reCAPTCHA';
import { isArkoseBotManagerForSignupEnabled } from 'bundles/authentication/utils/experimentUtils';
import GrowthAcquisitionExperiments from 'bundles/epic/clients/GrowthAcquisition';

export type Props = {
  arkoseBotManager?: {
    context?: ArkoseBotManagerContext;
    publicKey?: string;
  };
  reCAPTCHA?: {
    container?: string | HTMLElement;
    options?: Options;
  };
  shouldBypassArkose?: boolean;
  shouldBypassRecaptcha?: boolean;
};

export type BotManager = {
  arkoseBotManager?: ArkoseBotManager;
  isRetrievingTokenRef?: React.MutableRefObject<boolean>;
  getToken: () => Promise<string>;
  reset: () => void;
};

/**
 * Custom hook to manage bot protection during authentication flows by integrating Arkose Labs and reCAPTCHA.
 *
 * This hook determines whether to use Arkose Labs' bot detection
 * or fall back to reCAPTCHA based on configuration, experiments, or error conditions.
 * It abstracts the complexities of managing these services, such as token retrieval,
 * error handling, and bypass logic.
 *
 * Props:
 * - `arkoseBotManager`: Configuration object for Arkose Labs, including:
 *   - `context`: Provides an existing Arkose context to avoid reinitialization; use this to initialize Arkose on page load.
 *   - `publicKey`: Custom Arkose public key for token generation (optional).
 * - `reCAPTCHA`: Configuration object for reCAPTCHA, including:
 *   - `container`: HTML container or ID for reCAPTCHA initialization.
 *   - `options`: Additional options to configure reCAPTCHA behavior.
 * - `shouldBypassArkose`: Flag to bypass Arkose Labs integration.
 * - `shouldBypassRecaptcha`: Flag to bypass reCAPTCHA integration.
 *
 * Returns:
 * - `arkoseBotManager`: Instance of the Arkose Labs bot manager.
 * - `isRetrievingTokenRef`: A ref to track the token retrieval state.
 * - `getToken`: Function to retrieve a bot verification token (from Arkose Labs or reCAPTCHA).
 * - `reset`: Function to reset Arkose Labs and reCAPTCHA states.
 */
const useBotManager = (props: Props): BotManager => {
  const {
    shouldBypassArkose,
    shouldBypassRecaptcha,
    arkoseBotManager: { context: arkoseBotManagerContext, publicKey: arkosePublicKey } = {},
    reCAPTCHA: { container: reCAPTCHAContainer, options: reCAPTCHAOptions } = {},
  } = props;
  const {
    arkoseBotManager: arkoseBotManagerInstanceFromContext,
    isRetrievingTokenRef: isRetrievingTokenRefFromContext,
  } = arkoseBotManagerContext || {};

  const isRetrievingTokenRef = useRef(false);

  const isCheckbox = GrowthAcquisitionExperiments.get('useEnterpriseCheckboxReCaptchaSignup');
  const shouldUseArkoseBotManager = isArkoseBotManagerForSignupEnabled();

  const recaptchaInstance = useRecaptcha(
    reCAPTCHAContainer || SIGNUP_REDESIGN_RECAPTCHA_ID,
    { ...reCAPTCHAOptions, checkbox: isCheckbox },
    shouldBypassRecaptcha
  );

  const setIsRetrievingTokenValue = useCallback(
    (value = false) => {
      if (isRetrievingTokenRefFromContext) {
        isRetrievingTokenRefFromContext.current = value;
        return;
      }
      isRetrievingTokenRef.current = value;
    },
    [isRetrievingTokenRefFromContext]
  );

  const onHideCallback = useCallback(() => {
    setIsRetrievingTokenValue(false);
  }, [setIsRetrievingTokenValue]);

  const arkoseBotManager = useArkoseBotManager({
    publicKey: arkosePublicKey || ARKOSE_PUBLIC_KEY_SIGN_UP,
    shouldSkipHook: shouldBypassArkose || !!arkoseBotManagerContext || !shouldUseArkoseBotManager,
    onHideCallback,
  });

  const arkoseBotManagerInstance = arkoseBotManagerInstanceFromContext || arkoseBotManager;

  return {
    arkoseBotManager: arkoseBotManagerInstance,
    isRetrievingTokenRef: isRetrievingTokenRefFromContext || isRetrievingTokenRef,
    reset: () => {
      if (shouldUseArkoseBotManager) {
        arkoseBotManagerInstance?.reset();
      }
      // Since we fall back to reCAPTCHA when there are Arkose client-side errors,
      // we need to reset reCAPTCHA upon error to prevent duplicate tokens.
      recaptchaInstance?.reset();
    },
    getToken: async () => {
      let token;

      if (shouldUseArkoseBotManager) {
        setIsRetrievingTokenValue(true);

        try {
          token = await arkoseBotManagerInstance?.getToken();
        } catch (error) {
          // Fallback to recaptcha if the arkose triggers client side errors
          token = await recaptchaInstance?.getResponse();
        }

        // Reset the state after token retrieval attempt
        setIsRetrievingTokenValue(false);
      } else {
        token = await recaptchaInstance?.getResponse();
      }

      return token;
    },
  };
};

export default useBotManager;
